import React, { useEffect, useState, useRef } from 'react';
import api from '../configuracion/config';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import 'alertifyjs/build/css/themes/default.css';
import { estiloAlertify } from '../Funciones';
import { DateTime } from 'luxon';

const InsertarAnotacion = ({
  mostrarModalInsertar,
  cerrarModalInsertar,
  guardarModalInsertar,
}) => {
  ///--HORA EN EJECUCION
  const [hora, setHora] = useState(
    new Date().toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    })
  );
  useEffect(() => {
    const interval = setInterval(() => {
      setHora(
        new Date().toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        })
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  const [formulario, setFormulario] = useState({
    dteFecha: DateTime.local().toISODate(), //DateTime.local().toISODate(),//new Date().toISOString().split('T')[0],
    tmeHora: hora, // Ajuste aquí
    strAsunto: '',
    strAnotacion: '',
    intIdSede: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'intIdSede') {
      setFormulario({
        ...formulario,
        intIdSede: value,
      });
    } else {
      setFormulario({
        ...formulario,
        [name]: value,
      });
    }
  };

  const closeModal = () => {
    setFormulario({
      dteFecha: '',
      tmeHora: '',
      strAsunto: '',
      strAnotacion: '',
      intIdSede: '',
    });
    cerrarModalInsertar();
  };

  const [sedes, setSedes] = useState([]);
  const [asuntos, setAsuntos] = useState([]);

  useEffect(() => {
    if (mostrarModalInsertar) {
      setFormulario({
        dteFecha: DateTime.local().toISODate(), //new Date().toISOString().split('T')[0],
        tmeHora: hora,
        strAsunto: '',
        strAnotacion: '',
        intIdSede: '-1',
      });

      api
        .post('/SedesActivas')
        .then((response) => {
          setSedes(response.data);
        })
        .catch((error) => {
          console.error('Error al obtener las sedes:', error);
        });

      api
        .get('/Asuntos')
        .then((response) => {
          setAsuntos(response.data);
        })
        .catch((error) => {
          console.error('Error al obtener los Asuntos:', error);
        });
    }
  }, [mostrarModalInsertar]);

  const dteFechaRef = useRef(null);
  const tmeHoraRef = useRef(null);
  const strAsuntoRef = useRef(null);
  const strAnotacionRef = useRef(null);
  const intIdSedeRef = useRef(null);

  const validarGuardarModalInsertar = () => {
    const { strAsunto, strAnotacion, intIdSede } = formulario;
    console.log('Valores del formulario:', formulario); // Para verificar los valores actuales

    if (strAsunto === 'Seleccionar' || strAsunto === '') {
      alertify.error('Por favor, Seleccione el Asunto.');
      estiloAlertify(0);
      strAsuntoRef.current.focus();
      return;
    } else if (intIdSede === '-1') {
      alertify.error('Por favor, seleccione la Sede.');
      estiloAlertify(0);
      intIdSedeRef.current.focus();
      return;
    } else if (!strAnotacion) {
      alertify.error('Por favor, digite la anotación.');
      estiloAlertify(0);
      strAnotacionRef.current.focus();
      return;
    } else {
      alertify
        .confirm('¿ Desea guardar esta Minuta ?', async (e) => {
          if (e) {
            guardarModalInsertar({
              ...formulario,
              tmeHora: hora, // Asegúrate de utilizar el valor actualizado de hora
            });
          }
        })
        .set({
          labels: {
            ok: 'SI', // Etiqueta del botón OK
            cancel: 'NO', // Etiqueta del botón Cancelar
          },
          title: 'AVISO', // Título personalizado
        });
      estiloAlertify(2);
    }
  };

  return (
    <Modal show={mostrarModalInsertar} onHide={closeModal}>
      <Modal.Header closeButton className="bg-success text-light ">
        <Modal.Title className="small">Nueva Minuta</Modal.Title>
      </Modal.Header>

      <Modal.Body
        style={{ background: 'linear-gradient(to right, #0000, #85d4)' }}
      >
        <form className="mt-2">
          <div className="row">
            {!isVisible && (
              <div className="col-md-6 ">
                <div className="form-group">
                  <label htmlFor="strAsunto">Fecha:</label>
                  <input
                    type="date"
                    name="dteFecha"
                    id="dteFecha"
                    value={formulario.dteFecha}
                    onChange={handleInputChange}
                    className="form-control form-control-sm"
                    max={new Date().toISOString().split('T')[0]}
                    ref={dteFechaRef}
                    readOnly
                  />
                </div>
              </div>
            )}

            {!isVisible && (
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="tmeHora">Hora</label>
                  <input
                    type="time"
                    name="tmeHora"
                    id="tmeHora"
                    value={hora}
                    onChange={handleInputChange}
                    className="form-control form-control-sm"
                    ref={tmeHoraRef}
                    readOnly
                    //style={{ display: 'none' }} // Oculta el campo completamente
                  />
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group mt-2">
                <label htmlFor="strAsunto">Asunto</label>
                <select
                  name="strAsunto"
                  id="strAsunto"
                  onChange={handleInputChange}
                  className="form-control form-control-sm "
                  ref={strAsuntoRef}
                >
                  <option value="">Seleccionar</option>
                  {asuntos.map((asunto) => (
                    <option key={asunto.idAsunto} value={asunto.strAsunto}>
                      {asunto.strAsunto}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group mt-2">
                <label htmlFor="intIdSede">Sede</label>
                <select
                  name="intIdSede"
                  id="intIdSede"
                  onChange={handleInputChange}
                  className="form-control form-control-sm"
                  ref={intIdSedeRef}
                >
                  <option value="-1">Seleccionar</option>
                  {sedes.map((sede) => (
                    <option key={sede.intIdSede} value={sede.intIdSede}>
                      {sede.strSede}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="form-group mt-2">
                <label htmlFor="strAnotacion">Anotación</label>
                <textarea
                  name="strAnotacion"
                  id="strAnotacion"
                  onChange={handleInputChange}
                  className="form-control form-control-sm"
                  rows="5"
                  ref={strAnotacionRef}
                />
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>

      <Modal.Footer
        style={{ background: 'linear-gradient(to right, #0000, #85d3)' }}
      >
        <Button
          variant="outline-danger"
          className="btn btn-sm"
          onClick={closeModal}
        >
          Cerrar
        </Button>
        <Button
          variant="outline-secondary"
          className="btn btn-sm"
          onClick={validarGuardarModalInsertar}
        >
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { InsertarAnotacion };
