import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import 'alertifyjs/build/css/themes/default.css';
import { estiloAlertify } from '../Funciones';

const InsertarSede = ({
  mostrarModalInsertar,
  cerrarModalInsertar,
  guardarModalInsertar,
}) => {

  // Estado para los campos del formulario
  const [formulario, setFormulario] = useState({
    intIdSede: '',
    strSede: '',
    strDescripcion: '',
    bolEstado: true
  });

  const strSedeRef = useRef(null);
  const strDescripcionRef = useRef(null);

  //const userId = localStorage.getItem('userId');

// Función para manejar cambios en los campos del formulario
const handleInputChange = (event) => {
  const { name, value } = event.target;
  if (name === 'intIdSede') {
    setFormulario({
      ...formulario,
      intIdSede: value,
    });
  } else if (name === 'bolEstado') {
    // Convertir el valor de bolEstado a booleano
    setFormulario({
      ...formulario,
      bolEstado: value === 'true',
    });
  } else {
    setFormulario({
      ...formulario,
      [name]: value,
    });
  }
};


  // Función para cerrar el modal y reiniciar el formulario
  const closeModal = () => {
    setFormulario({
      intIdSede: '',
      strSede: '',
      strDescripcion: '',
      bolEstado: true
    });
    cerrarModalInsertar();
  };

  useEffect(() => {
    if (mostrarModalInsertar) {
      setFormulario({
        intIdSede: '',
        strSede: '',
        strDescripcion: '',
        bolEstado: true
      });
    }
  }, [mostrarModalInsertar]);


  const validarGuardarModalInsertar = () => {
    const { strSede, strDescripcion, bolEstado } = formulario;

    if (!strSede) {
      alertify.error('Por favor, diligencie la Sede.');
      estiloAlertify(0);
      strSedeRef.current.focus();
      return;
    } else if (!strDescripcion) {
      alertify.error('Por favor, diligencie la Descripcion.');
      estiloAlertify(0);
      strDescripcionRef.current.focus();
      return;
    }
    else if (bolEstado === undefined) {
      alertify.error('Por favor, Seleccione el estado');
      estiloAlertify(0);
      return;
    }
    
    else {
      
      alertify
        .confirm('¿Desea guardar ésta Sede?', async (e) => {
          if (e) {
             guardarModalInsertar(formulario);
          }
        })
        .set({
          labels: {
            ok: 'SI', // Etiqueta del botón OK
            cancel: 'NO', // Etiqueta del botón Cancelar
          },
          title: 'AVISO', // Título personalizado
        });
    }
  };

  return (
    <Modal show={mostrarModalInsertar} onHide={closeModal}>
      <Modal.Header closeButton className="bg-success text-light ">
        <Modal.Title className="small">Nueva Sede</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ background: 'linear-gradient(to right, #0000, #85d4)' }}>
        <form>
          <div className="row">
            <div className="form-group">
              <label htmlFor="strSede">Sede:</label>
              <input
                type="text"
                name="strSede"
                id="strSede"
                onChange={handleInputChange}
                className="form-control form-control-sm "
                ref={strSedeRef}
                placeholder="Nombre de la Sede"
              />
            </div>

            <div className="form-group mt-2">
              <label htmlFor="strDescripcion">Descripcion</label>
              <input
                type="text"
                name="strDescripcion"
                id="strDescripcion"
                onChange={handleInputChange}
                className="form-control form-control-sm "
                ref={strDescripcionRef}
                placeholder="Descripcion de la Sede"
              />
            </div>

            <div className="form-group mt-2">
              <label htmlFor="bolEstado">Estado</label>
                <select
                    name="bolEstado"
                    id="bolEstado"
                    onChange={handleInputChange}
                    className="form-control form-control-sm"
                     value={formulario.bolEstado}
                     
                   >
                    <option value = {true}  >Activo</option>
                    <option value = {false} >Inactivo</option>                    
                  </select>
                </div>


          </div>
        </form>
      </Modal.Body>

      <Modal.Footer style={{ background: 'linear-gradient(to right, #0000, #85d3)' }}>
        <Button
          variant="outline-danger"
          className="btn btn-sm"
          onClick={closeModal}
        >
          Cerrar
        </Button>
        <Button
          variant="outline-secondary"
          className="btn btn-sm"
          onClick={validarGuardarModalInsertar}
        >
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { InsertarSede };
