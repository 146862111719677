import Header from '../../Plantilla/Header'; // Importa el componente Header
import Footer from '../../Plantilla/Footer'; // Importa el componente Footer
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../configuracion/config';
import { InsertarSede } from '../modales/modalInsertarSedes';
import ActualizarSede from '../modales/modalActualizarSedes';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import 'alertifyjs/build/css/themes/default.css';
import { estiloAlertify } from '../Funciones';
import {
  faPlus,
  faMagnifyingGlass,
  faFilePen,
  faTrash,
  faFaceFrown,
} from '@fortawesome/free-solid-svg-icons';



function TablaSedes() {
  const [sedes, setSedes] = useState([]);
  const itemsPerPage = 10;
  const pageCount = Math.ceil(sedes.length / itemsPerPage);
  const [searchInput, setSearchInput] = useState('');
  const [mostrarModalInsertar, setMostrarModalInsertar] = useState(false);
  const [mostrarModalActualizar, setMostrarModal] = useState(false);
  const [drwSede, setDrwSede] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const userTipo = localStorage.getItem('userType');

  const load = async () => {
    try {
      const response = await api.post('/Sedes');

      if (response.status === 200) {
        setSedes(response.data);
      }
    } catch (error) {
      console.error('Error al obtener las Sedes:', error);
    }
  };

  useEffect(() => {
    load();
  }, []);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleInputChange = (event) => {
    setSearchInput(event.target.value);
    setPageNumber(0);
  };

  const funcionMostrarModalInsertar = () => {
    setMostrarModalInsertar(true);
  };

  const funcionCerrarModalInsertar = () => {
    setMostrarModalInsertar(false);
  };

  const funcionGuardarSede = async (formulario) => {
    const { strSede, strDescripcion, bolEstado } = formulario;
    const dttSede = {
      strSede,
      strDescripcion,
      bolEstado
    };

    try {
      const buscarSede = await api.post(`/Sedes`);
      const sedeExistente = buscarSede.data.find(
        (sede) => sede.strSede.toLowerCase() === strSede.toLowerCase()
      );

      if (sedeExistente) {
        alertify.error(
          `Ya existe la Sede  ${sedeExistente.strSede} `
        );
        estiloAlertify(0);
        return;
      } 
      else 
      {  
          
          const response = await api.post('/InsertarSede', dttSede);
          if (response.status === 200) {
            load();
            alertify.success('Guardado');
            console.log(response);
            estiloAlertify(1);
            funcionCerrarModalInsertar();
          }
          //console.log(dttSede);
        
      }
    } catch (error) {
      console.error('Error al insertar la Sede:', error);
    }
  };

  const funcionEliminarSede = async (intIdSede) => {
    alertify
      .confirm('¿Está seguro que desea eliminar ésta Sede?', async (e) => {
        if (e) {
          try {
            // Realiza la llamada a la API para eliminar la sede
            const response = await api.delete(`/EliminarSede/${intIdSede}`);
  
            // Verifica si la respuesta del servidor fue exitosa
            if (response.status === 200) {             
              // Si la sede fue eliminada correctamente
              if (response.data === 'Eliminado') {
                setSedes(sedes.filter((item) => item.intIdSede !== intIdSede));
                load();
                alertify.success('Sede eliminada correctamente.');
                estiloAlertify(1);
              } 
              // Si la sede está asociada a anotaciones
              else if (response.data === 'Asociada') {
                alertify.warning('La sede ya se encuentra Asociada');
                estiloAlertify(3);
              }
            }
          } catch (error) {
            // Manejo de errores de la API
            if (error.response) {
                alertify.warning(error.response.data);
                estiloAlertify(3);
            } else if (error.request) {
              alertify.error('No se recibió respuesta del servidor.');
              estiloAlertify(0);
            } else {
              alertify.error(`Error al eliminar la sede: ${error.message}`);
              estiloAlertify(0);
            }
            
          }
        }
      })
      .set({
        labels: {
          ok: 'SI', // Etiqueta del botón OK
          cancel: 'NO', // Etiqueta del botón Cancelar
        },
        title: 'AVISO', // Título personalizado
      });
  };
  
  
 
  

  const funcionMostrarModalActualizar = (sede) => {
    setDrwSede({
      ...sede,
    });
    setMostrarModal(true);
  };

  const funcionCerrarModalActualizar = () => {
    setMostrarModal(false);
  };

  const funcionActualizarSede = async (sedeActualizar) => {
    if (sedeActualizar.intIdSede) {
      try {

          const response = await api.put(`/ActualizarSede/${sedeActualizar.intIdSede}`,sedeActualizar);
          if (response.status === 200) {
            load();
            alertify.success('Guardado.');
            estiloAlertify(1);
            funcionCerrarModalActualizar();
          }
        
      } catch (error) {
        console.error('Error al actualizar la anotación:', error);
      }
    }
  };

  // Lógica para aplicar el filtro
  const filteredData = sedes.filter((drw) => {
    const formattedSearchInput = searchInput.toLowerCase();
    const usuarioMatch = drw.strSede
      .toLowerCase()
      .includes(formattedSearchInput);
    const nomnreMatch = drw.strDescripcion
      .toLowerCase()
      .includes(formattedSearchInput);
    return usuarioMatch || nomnreMatch;
  });

  // Lógica para la paginación
  const offset = pageNumber * itemsPerPage;
  const paginatedData = filteredData.slice(offset, offset + itemsPerPage);
  return (
    <div>
      <style>
        {`
          .pagination-container {
              display: flex;
              justify-content: flex-end;
          }
          .pagination {
            display: inline-block;
            list-style-type: none;
            margin: 0;
            padding: 0;
          }
          .pagination li {
            display: inline;
          }
          .pagination a {
            color: black;
            float: left;
            padding: 0px 3px;
            text-decoration: none;
          }
          .pagination .active a {
            background-color: gray;
            color: white;
            padding: 0px 6px;
          }
          .tablescroll {
            overflow-y: auto;
          }
      `}
      </style>
      <header>
        <Header />
      </header>
      {userTipo === 'admin' ? (
        <main className=" container-fluid mt-2" style={{ flex: '1', minHeight: 'calc(100vh - 20px)' }} >
          <div className="card">
            <div className="card-header ">
              <div className="row">
                <div className="col-md-8 ">
                  <h5 className="card-title text-secondary">Sedes</h5>
                </div>
                <div className="col-md-3">
                  <div className="input-group input-group-sm">
                    <span className="input-group-text">
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Sede, Descripcion"
                      value={searchInput}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-1 col-sm-12 text-md-end text-sm-start mt-2 mt-sm-0">
                  <button
                    className="btn btn-outline-success btn-sm float-end"
                    onClick={funcionMostrarModalInsertar}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Nueva
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive tablescroll">
                <table className="table  table-hover table-sm table-bordered">
                  <thead className="table table-light">
                    <tr>
                      <th className="text-secondary small col-1">ID</th>
                      <th className="text-secondary small ">Sede</th>
                      <th className="text-secondary small ">Descripcion</th>
                      <th className="text-secondary small text-center ">Estado</th>
                      <th className="small col-1  text-center text-white bg-success ">
                        Acciones
                      </th>
                    </tr>
                  </thead>
                  <tbody className="small align-middle">
                    {paginatedData.length === 0 ? (
                      <tr>
                        <td colSpan="7">No hay registros</td>
                      </tr>
                    ) : (
                      paginatedData.map((drw) => (
                        <tr key={drw.intIdSede}>
                          <td className="small">{drw.intIdSede}</td>
                          <td className="small">{drw.strSede}</td>
                          <td className="small">{drw.strDescripcion}</td>
                          <td className= {`small text-center ${drw.bolEstado === false ? 'text-danger' : '' }`} >{drw.bolEstado ? 'Activo' : 'Inactivo'}</td>
                          <td className=" text-center ">
                            <div
                              className="btn-group "
                              role="group"
                              aria-label=""
                            >
                              <button
                                className="btn btn-sm "
                                onClick={() =>
                                  funcionMostrarModalActualizar(drw)
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faFilePen}
                                  className="text-secondary"
                                />
                              </button>
                              <button
                                className="btn  btn-sm"
                                onClick={() =>
                                  funcionEliminarSede(drw.intIdSede)
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className="text-danger"
                                />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="card-footer">
              <div className="row">
                <div className="col-md-8">
                  {/* Contenido de la primera columna */}
                </div>
                <div className="col-md-4 text-end small">
                  <ReactPaginate
                    previousLabel={'Anterior'}
                    nextLabel={'Siguiente'}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <InsertarSede
              mostrarModalInsertar={mostrarModalInsertar}
              cerrarModalInsertar={funcionCerrarModalInsertar}
              guardarModalInsertar={funcionGuardarSede}
            />
            {drwSede && (
              <ActualizarSede
                mostrarModalActualizar={mostrarModalActualizar}
                cerrarModalActualizar={funcionCerrarModalActualizar}
                actualizarModal={funcionActualizarSede}
                drwSede={drwSede}
              />
            )}
          </div>
        </main>
      ) : (
        <div className="container ">
          <div className="row mt-5">
            <FontAwesomeIcon
              icon={faFaceFrown}
              beat
              size="4x"
              style={{ color: '#9aaf98' }}
            />
          </div>
          <div className="row mt-3 text-center">
            <p className="text-secondary">Página no encontrada ...</p>
          </div>
        </div>
      )}

      <footer>
         <Footer />
      </footer>
    </div>
  );
}

export default TablaSedes;
