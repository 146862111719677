import Header  from '../../Plantilla/Header'; // Importa el componente Header
import Footer from '../../Plantilla/Footer'; // Importa el componente Footer
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../configuracion/config';
import { InsertarUsuario } from '../modales/modalInsertarUsuarios';
import ActualizarUsuario from '../modales/modalActualizarUsuarios';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import 'alertifyjs/build/css/themes/default.css';
import { estiloAlertify} from '../Funciones'

import {
  faPlus,
  faMagnifyingGlass,
  faFilePen,
  faFaceFrown,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';

function TablaUsuarios() {
  const [usuarios, setUsuarios] = useState([]);
  const itemsPerPage = 10;
  const pageCount = Math.ceil(usuarios.length / itemsPerPage);
  const [searchInput, setSearchInput] = useState('');
  const [mostrarModalInsertar, setMostrarModalInsertar] = useState(false);
  const [mostrarModalActualizar, setMostrarModal] = useState(false);
  const [drwUsuario, setDrwUsuario] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const userTipo = localStorage.getItem('userType');


  const load = async () => {
    try {
      
      const response = await api.get('/usuarios');

      if (response.status === 200) {
        setUsuarios(response.data);
      }
    } catch (error) {
      console.error('Error al obtener los Usuarios:', error);
    }
  };

  useEffect(() => {
        load();          
  }, []);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleInputChange = (event) => {
    setSearchInput(event.target.value);
    setPageNumber(0);
  };

  const funcionMostrarModalInsertar = () => {
    setMostrarModalInsertar(true);
  };

  const funcionCerrarModalInsertar = () => {
    setMostrarModalInsertar(false);
  };

  const funcionGuardarUsuario = async (formulario) => {
      const { strUsuario, strNombres, strPassword, strTipo, bolActivo } =  formulario;
      const dttUsuario = {
        strUsuario,
        strNombres,
        strPassword,
        strTipo,
        bolActivo,
      };

      try {

        const buscarUsuario = await api.get(`/usuarios`);
        const usuarioExistente = buscarUsuario.data.find((usuario) => usuario.strUsuario === strUsuario);

         if (usuarioExistente) {
          alertify.warning(`El usuario ${usuarioExistente.strUsuario} ya se encuentra registrado como:  ${usuarioExistente.strNombres}`);
           estiloAlertify(3);
           return;
         } 
         else 
         {                    
            const response = await api.post('/InsertarUsuario', dttUsuario);
            if (response.status === 200) {
                load();
                alertify.success('Guardado.');
                estiloAlertify(1);
            }
           
         }
      } catch (error) {
        console.error('Error al obtener el usuario:', error);
      }
      funcionCerrarModalInsertar();
    
  };

  const funcionEliminarUsuario = async (strUsuario) => {
    alertify
      .confirm('¿Está seguro que desea eliminar este Usuario?', async (e) => {
        if (e) {
          try {
            // Realiza la llamada a la API para eliminar el usuario
            const response = await api.delete(`/EliminarUsuario/${strUsuario}`);
  
            // Verifica si la respuesta del servidor fue exitosa
            if (response.status === 200) {             
              // Si el usuario fue eliminado correctamente
              if (response.data === 'Eliminado') {
                setUsuarios(usuarios.filter((item) => item.strUsuario !== strUsuario));
                load();
                alertify.success('Usuario eliminado correctamente.');
                estiloAlertify(1);
              } 
              // Si el usuario está asociado a anotaciones
              else if (response.data === 'Asociada') {
                alertify.warning('No puede eliminar el usuario, está siendo utilizado en las Minutas.');
                estiloAlertify(3);
              }
            }
          } catch (error) {
            // Manejo de errores de la API
            if (error.response) {
              alertify.warning("No puede eliminar el usuario, tiene Minutas Asociadas");
              estiloAlertify(3);
            } else if (error.request) {
              // La solicitud fue hecha pero no se recibió respuesta
              console.error('Error en la solicitud:', error.request);
              alertify.error('No se recibió respuesta del servidor.');
            } else {
              // Algo sucedió al configurar la solicitud
              console.error('Error', error.message);
              alertify.error(`Error al eliminar el usuario: ${error.message}`);
            }
            estiloAlertify(0);
          }
        }
      })
      .set({
        labels: {
          ok: 'SI', // Etiqueta del botón OK
          cancel: 'NO', // Etiqueta del botón Cancelar
        },
        title: 'AVISO', // Título personalizado
      });
  };
  




  const funcionMostrarModalActualizar = (usuario) => {
    setDrwUsuario({
      ...usuario,
    });

    setMostrarModal(true);
  };

  const funcionCerrarModalActualizar = () => {
    setMostrarModal(false);
  };

  const funcionActualizarUsuario = async (usuarioActualizar) => {
    if (usuarioActualizar.strUsuario) {
      try 
      {         
          const response = await api.put(`/ActualizarUsuario/${usuarioActualizar.strUsuario}`,usuarioActualizar);
         if (response.status === 200) {
            load();
            alertify.success('Guardado.');
            estiloAlertify(1);
            funcionCerrarModalActualizar();
         }         
      
      } catch (error) {
        console.error('Error al actualizar la anotación:', error);
      }
    }
  };


  const funcionActualizarPassword = async (usuarioActualizar) => {   
  
    try {
          const { strPassword } = usuarioActualizar;  
          const data = {strUsuario: drwUsuario.strUsuario, strPassword: strPassword };

          const response = await api.put(`/ActualizaPassword`,data);  
          if (response.status === 200) {
              load();
              alertify.success('Guardado.');
              estiloAlertify(1);
              funcionCerrarModalActualizar();
        }
      
    } catch (error) {
      console.error('Error al actualizar la contraseña:', error);
    }
  };
  

  // Lógica para aplicar el filtro
  const filteredData = usuarios.filter((drw) => {
    const formattedSearchInput = searchInput.toLowerCase();
    const usuarioMatch = drw.strUsuario.toLowerCase().includes(formattedSearchInput);
    const nomnreMatch  = drw.strNombres.toLowerCase().includes(formattedSearchInput);  
    return usuarioMatch || nomnreMatch ;
  });



  // Lógica para la paginación
  const offset = pageNumber * itemsPerPage;
  const paginatedData = filteredData.slice(offset, offset + itemsPerPage);
  return (
   <div style={{ minHeight: '10vh', display: 'flex', flexDirection: 'column' }}>
      <style>
       {`
          .pagination-container {
              display: flex;
              justify-content: flex-end;
          }
          .pagination {
            display: inline-block;
            list-style-type: none;
            margin: 0;
            padding: 0;
          }
          .pagination li {
            display: inline;
          }
          .pagination a {
            color: black;
            float: left;
            padding: 0px 3px;
            text-decoration: none;
          }
          .pagination .active a {
            background-color: gray;
            color: white;
            padding: 0px 6px;
          }
          .tablescroll {
            overflow-y: auto;
            //height: 390px;
          }
      `}
      </style>
      <header>
        <Header />
      </header>
      {userTipo === 'admin' ? (

     <main className="container-fluid mt-2" style={{ flex: '1', minHeight: 'calc(100vh - 20px)' }}>
  
        <div className="card h-10">
          <div className="card-header ">
            <div className="row">
              <div className="col-md-8 ">
                <h5 className="card-title text-secondary">Usuarios</h5>
              </div>
              <div className="col-md-3">
                <div className="input-group input-group-sm">
                  <span className="input-group-text">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="ID, Usuario"
                    value={searchInput}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-1 col-sm-12 text-md-end text-sm-start mt-2 mt-sm-0">
                <button
                  className="btn btn-outline-success  btn-sm float-end"
                  onClick={funcionMostrarModalInsertar}
                >
                  <FontAwesomeIcon icon={faPlus} /> Nuevo
                </button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive tablescroll d-sm-block">
              <table className="table  table-hover table-sm table-bordered">
                <thead className="table table-light ">
                  <tr>
                    <th className='text-secondary small col-1 ' >ID</th>
                    <th className='text-secondary small ' >Usuario</th>
                    <th className='text-secondary small col-1 text-center' >Tipo</th>
                    <th className='text-secondary small col-1 text-center' >Estado</th>                 
                    {userTipo === 'admin' && <th className='text-white bg-success small col-1 text-center' >Acciones</th>}
                  </tr>
                </thead>
                 <tbody className="small align-middle">
                  {paginatedData.length === 0 ? (
                    <tr>
                      <td colSpan="7">No hay registros</td>
                    </tr>
                  ) : (
                    paginatedData.map((drw) => (
                      <tr  key={drw.strUsuario}>
                        <td className="small">{drw.strUsuario}</td>
                        <td className="small">{drw.strNombres}</td>
                       {/* <td className="small text-center"> {drw.strTipo}</td> */}
                       <td className="small text-center">
                       {{
                         'user': 'Usuario',
                         'supervisor': 'Supervisor',
                         'admin': 'Administrador'
                       }[drw.strTipo]}
                     </td>
                     
                        <td className= {`small text-center ${drw.bolActivo === false ? 'text-danger' : '' }`} >{drw.bolActivo ? 'Activo' : 'Inactivo'}</td>
                        {userTipo === 'admin' &&                         
                            <td className='text-center'>
                              <div className="btn-group" role="group" aria-label="">
                                <button
                                  className="btn btn-sm"
                                  onClick={() => funcionMostrarModalActualizar(drw)}
                                >
                                  <FontAwesomeIcon
                                    icon={faFilePen}
                                    className="text-secondary"
                                  />
                                </button>
                                <button
                                  className="btn  btn-sm"
                                  onClick={() =>
                                    funcionEliminarUsuario(drw.strUsuario)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faTrashCan}
                                    className="text-danger"
                                  />
                                </button>
                              </div>
                            </td>                        
                        }                  
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="card-footer">
            <div className="row">
              <div className="col-md-8">
                {/* Contenido de la primera columna */}
              </div>
              <div className="col-md-4 text-end small">
                <ReactPaginate
                  previousLabel={'Anterior'}
                  nextLabel={'Siguiente'}
                  breakLabel={'...'}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <InsertarUsuario
            mostrarModalInsertar={mostrarModalInsertar}
            cerrarModalInsertar={funcionCerrarModalInsertar}
            guardarModalInsertar={funcionGuardarUsuario}
          />
          {drwUsuario && (
            <ActualizarUsuario
              mostrarModalActualizar={mostrarModalActualizar}
              cerrarModalActualizar={funcionCerrarModalActualizar}
              actualizarModal={funcionActualizarUsuario}
              actualizaPassword={funcionActualizarPassword}
              drwUsuario={drwUsuario}
            />
          )}
        </div>
      </main>
       ) : (
        <div className="container ">
            <div className='row mt-5'>
            <FontAwesomeIcon icon={faFaceFrown} beat size="4x" style={{color: "#9aaf98",}} />
            </div>
            <div className='row mt-3 text-center'>
            <p className='text-secondary'>Página no encontrada ...</p>

            </div>          
        </div>
      )}
    <footer>
     <Footer/>
    </footer>
    </div>
  );
}

export default TablaUsuarios;
